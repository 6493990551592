<template>
  <aside
    class="site-sidebar site-sidebar--dark-popper"
    :style="{'width': sidebarFold ? '50px': '176px'}"
  >
    <SubMenu
      :defaultActive="menuActiveName || 'home-index'"
      :menuChildren="(menuChildren || []).filter(e => e.isShow)"
      :isCollapse="sidebarFold"
      :dynamicMenuRoutes="dynamicMenuRoutes"
    ></SubMenu>
    <div class="menu-foot" @click="packUp">
      <icon-svg name="zhedie" class="menu-foot-icon"></icon-svg>
    </div>
  </aside>
</template>

<script>
import SubMenu from './main-sidebar-sub-menu'
import { isURL } from '../../utils/validate'
export default {
  name: 'home',
  data () {
    return {
      menuList: [],
      dynamicMenuRoutes: []
    }
  },
  methods: {
    packUp () {
      this.sidebarFold = !this.sidebarFold
    },
    routeHandle (route) {
      if (route.meta.isTab) {
        // tab选中, 不存在先添加
        var tab = this.mainTabs.filter(item => item.name === route.name)[0]
        if (!tab) {
          if (route.meta.isDynamic) {
            route = this.dynamicMenuRoutes.filter(item => item.name === route.name)[0]
            if (!route) {
              return console.error('未能找到可用标签页!')
            }
          }
          tab = {
            menuId: route.meta.menuId || route.name,
            name: route.name,
            title: route.meta.title,
            type: isURL(route.meta.iframeUrl) ? 'iframe' : 'module',
            iframeUrl: route.meta.iframeUrl || ''
          }
          this.mainTabs = this.mainTabs.concat(tab)
        }
        this.menuActiveName = tab.menuId + ''
        this.mainTabsActiveName = tab.name
      }
    }
  },
  created () {
    this.menuList = (this.$utils.getSessionStorage('menuList') || []).filter(e => e.isShow)
    this.dynamicMenuRoutes = this.$utils.getSessionStorage('dynamicMenuRoutes')
    this.routeHandle(this.$route)
  },
  computed: {
    sidebarFold: {
      get () {
        return this.$store.state.common.sidebarFold
      },
      set (val) {
        this.$store.commit('common/updateSidebarFold', val)
      }
    },
    menuChildren: {
      get () {
        return this.menuList[this.activeMenu] ? this.menuList[this.activeMenu].children : []
      }
    },
    activeMenu: {
      get () {
        return this.$store.state.common.activeMenu
      }
    },
    mainTabs: {
      get () { return this.$store.state.common.mainTabs },
      set (val) { this.$store.commit('common/updateMainTabs', val) }
    },
    mainTabsActiveName: {
      get () { return this.$store.state.common.mainTabsActiveName },
      set (val) { this.$store.commit('common/updateMainTabsActiveName', val) }
    },
    menuActiveName: {
      get () { return this.$store.state.common.menuActiveName },
      set (val) { this.$store.commit('common/updateMenuActiveName', val) }
    }
  },
  components: {
    SubMenu
  },
  watch: {
    $route: 'routeHandle'
  }
}
</script>
